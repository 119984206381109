<script
    lang="ts"
    setup
>
    import type { KBArticle, KBCategory, KBItem } from '~/ts/types/knowledge-base'
    import { KBItemTypeEnum } from '~/ts/enums/knowledge-base'

    type Props = {
        item: KBItem
        currentItem: KBItem
        forceOpenCategory: boolean
        openCategoryIds: string[]
    }

    type Emit = {
        (event: 'set-current-item', item: KBItem): void
        (event: 'toggle-category', category: KBCategory): void
        (event: 'select-article', article: KBArticle): void
    }

    const props = defineProps<Props>()
    const emit = defineEmits<Emit>()

    const isCategoryOpenValue = computed<boolean>(() => {
        return props.openCategoryIds.includes(props.item._id)
    })

    const isCategoryOpen = computed<boolean>(() => {
        return props.forceOpenCategory || isCategoryOpenValue.value
    })

    const style = useCssModule()

    const itemClass = computed<string[]>(() => {
        const classes = [ style['tree-item'] ]

        if (props.currentItem._id === props.item._id) {
            classes.push(style['tree-item--active'])
        }

        return classes
    })

    const onClick = (): void => {
        if (props.item.type === KBItemTypeEnum.Article) {
            selectArticle(props.item as KBArticle)
        } else if (props.item.type === KBItemTypeEnum.Category) {
            toggleCategory(props.item as KBCategory)
        }
    }

    const toggleCategory = (item: KBCategory): void => emit('toggle-category', item)
    const selectArticle = (item: KBArticle): void => emit('select-article', item as KBArticle)
</script>

<template>
    <div
        :class="itemClass"
        :data-id="props.item._id"
    >
        <div
            key="content"
            :title="props.item.title"
            :class="$style['tree-item__content']"
            @mouseenter.self="emit('set-current-item', props.item)"
            @click="onClick()"
        >
            <AppIconMenuArrowDown
                v-if="props.item.type === KBItemTypeEnum.Category"
                key="icon-arrow"
                size="16"
                :class="[
                    $style['tree-item__content__chevron-down'],
                    { [$style['tree-item__content__chevron-down--active']]: isCategoryOpen },
                ]"
            />

            <div
                key="title"
                :class="$style['tree-item__content__title']"
            >
                <AppIconDocument
                    v-if="props.item.type === KBItemTypeEnum.Article"
                    size="16"
                    color="#8a8f9e"
                    style="margin-right: 16px"
                />

                <div class="truncate">
                    {{ props.item.title }}
                </div>
            </div>
        </div>

        <template v-if="(props.item.type === KBItemTypeEnum.Category) && isCategoryOpen">
            <AppFormFieldSelectKBItem
                v-for="nestedItem in props.item.children"
                :key="nestedItem._id"
                :item="nestedItem"
                :current-item="props.currentItem"
                :force-open-category="props.forceOpenCategory"
                :open-category-ids="openCategoryIds"
                :class="$style['tree-item--nested']"
                @set-current-item="emit('set-current-item', $event)"
                @toggle-category="toggleCategory"
                @select-article="selectArticle"
            />
        </template>
    </div>
</template>

<style
    lang="sass"
    module
>
    .tree-item-container
        display: flex
        flex-direction: column
        user-select: none

    .tree-item
        overflow: hidden
        position: relative

        $item: &

        &__content
            position: relative
            display: flex
            flex-direction: row
            align-items: center
            height: 32px
            margin: 2px 0
            padding: 0 10px
            background: #fff
            color: #8a8f9e
            border-radius: 8px
            cursor: pointer

            #{$item}--active > &
                background: #f6f7f8
                color: #000

                svg > path
                    fill: #000 !important

            &__chevron-down
                pointer-events: none
                margin-right: 16px
                transform: rotate(-90deg)

                &--active
                    transform: rotate(0deg)

                    > path
                        fill: #000

            &__title
                pointer-events: none
                white-space: nowrap
                overflow: hidden
                text-overflow: ellipsis
                display: flex
                align-items: center
                flex: 1
                font-size: 14px
                font-weight: 500
                line-height: 17px
                color: #000

        &--nested
            margin-left: 24px
</style>
